import React from "react"

import { FormattedMessage, useIntl } from "react-intl"

import withTemplate from "../withTemplate"
import chefShowingApp from "./chef-showing-app.svg"
import SEO from "~/components/Seo/Seo"
import {
  BalanceText,
  Body,
  Col,
  Container,
  Heading,
  HrefButton,
  Row,
} from "~/components/ui"

import css from "./Stub.module.scss"

function CommunityPostStub() {
  const intl = useIntl()
  return (
    <div className={css.root}>
      <SEO
        title={intl.formatMessage({
          id: "community-post-stub/seo-title",
        })}
        description={intl.formatMessage({
          id: "community-post-stub/seo-description",
        })}
      />

      <Container>
        <Row>
          <Col className={css.imageCol} width={[12, 4]}>
            <img className={css.chefImage} src={chefShowingApp} alt="" />
          </Col>
          <Col className={css.textCol} width={[12, 6]}>
            <BalanceText>
              {({ className }) => (
                <Heading className={className}>
                  <FormattedMessage id="community-post-stub/section:see-post-in-the-app" />
                </Heading>
              )}
            </BalanceText>

            <Body className={css.bodyText}>
              <FormattedMessage id="community-post-stub/text:see-post-in-the-app" />
            </Body>

            <HrefButton
              className={css.button}
              href={intl.formatMessage({ id: "url:django-mobile-app" })}
              color="original"
            >
              <FormattedMessage id="page-stub/action:get-the-app" />
            </HrefButton>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default withTemplate(CommunityPostStub)
